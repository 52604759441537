<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M23,9.81277213 L19.1204644,9.06240929 L21.3236575,5.77358491 L17.4441219,6.55587808 L18.2264151,2.67634253 L14.9375907,4.87953556 L14.1712627,1 L11.9840348,4.30478955 L9.81277213,1 L9.06240929,4.87953556 L5.77358491,2.67634253 L6.55587808,6.55587808 L2.67634253,5.77358491 L4.87953556,9.06240929 L1,9.81277213 L4.30478955,12 L1,14.1872279 L4.87953556,14.9375907 L2.67634253,18.2264151 L6.55587808,17.4441219 L5.77358491,21.3236575 L9.06240929,19.1204644 L9.81277213,23 L12,19.6952104 L14.1872279,23 L14.9535559,19.1204644 L18.2423803,21.3236575 L17.4600871,17.4441219 L21.3396226,18.2264151 L19.1204644,14.9375907 L23,14.1872279 L19.6952104,12 L23,9.81277213 L23,9.81277213 Z M18.0348331,12 C18.0348331,15.3367199 15.3367199,18.0348331 12,18.0348331 C11.0740203,18.0348331 10.179971,17.8113208 9.39767779,17.4441219 L10.2119013,14.0275762 L12.191582,14.0275762 C12.8780842,13.9956459 13.532656,13.9956459 14.2191582,13.7082729 C15.3686502,13.2133527 16.1988389,12.1436865 16.1988389,10.6748911 C16.1988389,10.0682148 16.0551524,9.46153846 15.7039187,8.98258345 C14.9854862,8.05660377 13.8838897,7.99274311 13.2133527,7.97677794 L9.33381713,7.97677794 L7.52975327,16.0391872 C6.57184325,14.969521 5.96516691,13.5645864 5.96516691,12 C5.96516691,8.66328012 8.66328012,5.96516691 12,5.96516691 C15.3367199,5.96516691 18.0348331,8.66328012 18.0348331,12 L18.0348331,12 Z M11.2496372,9.57329463 L12.4310595,9.57329463 C13.0217707,9.57329463 13.9637155,9.65312046 13.9637155,10.754717 C13.9637155,11.233672 13.7402032,11.7764877 13.3091437,12.0798258 C12.9259797,12.3671988 12.5587808,12.3671988 12.2075472,12.383164 L10.6110305,12.383164 L11.2496372,9.57329463 L11.2496372,9.57329463 Z"
    />
  </svg>
</template>
<script>
import { PLATIN_COIN_ICON } from '@/constants'

export default {
  name: PLATIN_COIN_ICON,
}
</script>
